import React from 'react'
import Layout from './../components/layout'
import PricingComponent from './../components/pricing'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout isWhiteHeader paddingTop grayBackground container>
      <SEO
          title='Pricing'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'product_page'}
          flowType={'none'} />
      <PricingComponent />
    </Layout>
  )
}

export default IndexPage
