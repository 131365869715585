import React from 'react'
import Typography from '@eig-builder/core-ui/Typography'
import PricingContainer from './pricing.styles'
import { PlanPricingPage } from '@eig-builder/module-plan-pricing-page'
import { getCurrencySymbol } from 'helpers/currency-helper'

const currencySymbol = getCurrencySymbol().symbol
const priceDefinition = {
  discount: 0.6,
  pro:{
    1: 22.95,
    12: 21.95,
    24: 18.95,
    36: 11.95,
    getPrices (term = 12){ 
      return priceDefinition.price(this, term)
  }
  },
  premium:{
    1: 29.95,
    12: 24.95,
    24: 19.95,
    36: 11.95,
    getPrices (term = 12){ 
      return priceDefinition.price(this, term)
  }
  },
  ecommerce:{
    1: 42.95,
    12: 32.95,
    24: 27.95,
    36: 11.95,
    getPrices (term = 12){ 
      return priceDefinition.price(this, term)
  }
},
  price (plan, term = 12) {
    return{
      totalDiscountedPrice: ((plan[term] * (1- this.discount)) * term).toFixed(2),
      totalPrice: plan[term] * term,
      monthlyDiscountedPrice : (plan[term] * (1- this.discount)).toFixed(2),
      monthlyPrice: plan[term] 
    }
  }
}

const pricingData = [
  {
    bannerText: 'Most Popular',
    title: 'Pro',
    subtitle: '',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: priceDefinition.pro.getPrices(24).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: priceDefinition.pro.getPrices(24).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: 'With a 2 year plan',
    buttonText: 'Choose Plan',
    features: [
      'FREE hosting',
      'Customizable Layouts',
      'Mobile Optimized',
      'Domain Name Included*',
      'Website Statistics',
      'Bookings',
      'Ad Credits Included**',
    ],
    showVat: false,
    featured: true,
    showFeatures: true,
    buttonFeatured: true,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    startOnboardingOnClick: () => {
      window.location.href = process.env.GATSBY_SIGNUP_SITE_URL
    },
  },
  {
    title: 'Premium',
    subtitle: '',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: priceDefinition.premium.getPrices(24).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: priceDefinition.premium.getPrices(24).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: 'With a 2 year plan',
    buttonText: 'Choose Plan',
    features: [
      'FREE hosting',
      'Customizable Layouts',
      'Mobile Optimized',
      'Domain Name Included*',
      'Website Statistics',
      'Bookings',
      'Ad Credits Included**',
      'Priority Support***',
    ],
    showVat: false,
    featured: false,
    showFeatures: true,
    buttonFeatured: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    startOnboardingOnClick: () => {
      window.location.href = process.env.GATSBY_SIGNUP_SITE_URL
    },
  },
  {
    title: 'eCommerce',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: priceDefinition.ecommerce.getPrices(24).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: priceDefinition.ecommerce.getPrices(24).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: 'With a 2 year plan',
    buttonText: 'Choose Plan',
    features: [
      'FREE hosting',
      'Customizable Layouts',
      'Mobile Optimized',
      'Domain Name Included*',
      'Website Statistics',
      'Bookings',
      'Ad Credits Included**',
      'Priority Support***',
      'eCommerce Store',
    ],
    showVat: false,
    featured: false,
    showFeatures: true,
    buttonFeatured: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    startOnboardingOnClick: () => {
      window.location.href = process.env.GATSBY_SIGNUP_SITE_URL
    },
  },
]

const PricingComponent = () => {
  return (
    <PricingContainer>
      <PricingContainer.pricingCards>
        <PlanPricingPage pricingCards={pricingData} showFeatureBar={false} />
      </PricingContainer.pricingCards>
      <PricingContainer.footerContainer>
        <Typography variant='body2' gutterBottom>
          *The promotional price is for the first term of service only and
          automatically renews at{' '}
          <a
            href={process.env.GATSBY_REGULAR_RATES}
            target='_blank'
            rel='noopener noreferrer'
          >
            regular rates
          </a>
          . For customers in regions in which VAT (Value Added Tax) or GST
          (Goods and Services Tax) is applicable, taxes are not included in our
          advertised price and will be charged separately and itemized on
          invoices and billing information. Qualified plans include a free
          domain registration for one year. If you wish to cancel, there is a
          non-refundable {getCurrencySymbol().domainPrice} domain fee.{' '}
          <a
            href={process.env.GATSBY_EU_VAT}
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more
          </a>
        </Typography>
        <Typography variant='body2' gutterBottom>
          **Drive high quality traffic to your website with targeted search ads.
          Plus, get $100 ad credits to spend on Google Ads ($25 min purchase).
          Valid for US/CA only
        </Typography>
        <Typography variant='body2' gutterBottom>
          ***Jump right to the front of the support line and get the help you
          need fast.
        </Typography>
      </PricingContainer.footerContainer>
    </PricingContainer>
  )
}

export default PricingComponent