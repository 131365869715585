import styled from 'styled-components'

const PricingContainer = styled.div`
  padding-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`

PricingContainer.footerContainer = styled.div`
  width: 100%;
  padding-top: 45px;
  align-self: center;
`

PricingContainer.pricingCards = styled.div`
  width: 100%;
  flex-grow: 2;
`

export default PricingContainer
